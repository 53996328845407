import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';


@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit
{

	email: any;
	password: any;
	user_name: any;		// set from localStorage
	isChecked: boolean = true
	show_password: boolean



	constructor(
		private api: ApiService,
		private router: Router,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService
	) { }

	ngOnInit(): void
	{ }

	login()
	{
		console.log('User Log In...')
		this.spinner.show()
		if (!this.email || !this.password)
		{
			this.toastr.error('All Fields are required. ', 'Error!');
			this.spinner.hide()
		} else
		{
			let data = {
				email: this.email,
				password: this.password
			}

			this.api.loginUser(data).then((res: any) =>
			{
				console.info('Getting Response [login()] -------', res)
				if (res.status == 200 && res.data.role == "admin")
				{
					this.api.userData=res.data
					localStorage.setItem('userData',JSON.stringify(res.data))
					// localStorage.setItem('restrictions', JSON.stringify({
					// 	add_student: res.data.isAllowToAddStudent,
					// 	edit_district_calendar: res.data.isAllowToEditDistrictCalendar,
					// 	edit_school_calendar: res.data.isAllowToEditSchoolCalendar,
					// 	edit_teacher: res.data.isAllowToEditTeacher,
					// 	edit_district_info: res.data.isAllowToeditDistrictInfo,
					// }))

					// localStorage.setItem('district_name', res.data.districtId[0].districtName) // for use in reporting

					localStorage.setItem('role', res.data.role)	 // for use in footer

					this.user_name = `${res.data.firstName.charAt(0).toUpperCase()}${res.data.firstName.slice(1)} ${res.data.lastName.charAt(0).toUpperCase()}${res.data.lastName.slice(1)}`
					localStorage.setItem('user_name', this.user_name) // store user name for use in footer

					this.api.setAccessToken(`${res.data.accessToken}`) // store access token for use in api

					localStorage.setItem('user_id', res.data._id) // for use in profile

					// making an array of schools for display in attendance component
					// to display which schools have admin access to ?
					// let schools = []
					// if (res.data.schoolId.length > 0)
					// {
					// 	res.data.schoolId.map((item) =>
					// 	{
					// 		schools.push([item._id, item.schoolName])
					// 	})
					// }
					// localStorage.setItem('schools', JSON.stringify(schools))  // for use in attendance

					this.toastr.success(`Welcome back ${this.user_name}. `, "Success")
					this.router.navigate(['/dashboard'])
					this.spinner.hide()
				} else if (res.status != 200)
				{
					this.toastr.error(res.message, "Error !")
				} else
				{
					this.toastr.error("Authorization Error! You are not authorized to use this email.", "Error !")
				}
				this.spinner.hide()
			})
		}
	}
}
