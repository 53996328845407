import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
	  private router: Router
  ) { }

  ngOnInit(): void {
  }

  home()
  {
	  console.log('Lets Moveeeeee.....')
	  console.clear()
	  // this.router.navigate(['/dashboard']);
    let check = localStorage.getItem('redirect')
    switch (check) {
      case 'reporting': {
        // console.log("i")
        this.router.navigate(['/dashboard/reporting'])
        break;
      }
      default: {
        this.router.navigate(['/dashboard'])
        break;
      }
    }
    localStorage.removeItem('redirect')
  }
}
