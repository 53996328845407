import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import
  {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse
  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ApiService } from './services/api.service';

@Injectable()
export class SetheaderInterceptor implements HttpInterceptor
{

  constructor(private api: ApiService) { }
  authToken: any

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>>
  {
    this.authToken = this.api.getAccessToken()

    if (this.api.isLoggedIn())
    {
      request = request.clone({
        // headers:{Authorization: this.authToken}

        headers: request.headers.set('Authorization', this.authToken)
      });

    }

    console.log("custom", request);

    // pass on the modified request object
    // return next.handle(request); 

    // return next.handle(request).pipe(do(
    //   (err: any) => {
    //     console.log("errorr",err);

    //     if (err instanceof HttpErrorResponse) {
    //       console.log(err);
    //       console.log('req url in error is  :: ' + request.url);
    //       if (err.status === 401) {
    //         console.log("hi in jhkfhk")
    //       }
    //     }
    //   }
    // ));

    // return next.handle(request).do((event: HttpEvent<any>) => {
    //   if (event instanceof HttpResponse) {
    //     // do stuff with response if you want
    //   }
    // }, (err: any) => {
    //   if (err instanceof HttpErrorResponse) {
    //     if (err.status === 401) {
    //       // redirect to the login route
    //       // or show a modal
    //     }
    //   }
    // });


    return next.handle(request).pipe(
      catchError((err) =>
      {
        if (err instanceof HttpErrorResponse)
        {
          console.log("error in authorixzaTION", err);

          if (err.status === 401)
          {
            localStorage.clear()
            console.log("ERROR MESSAGE", err.message);

          }
        }
        return throwError(err);
      })
    )

  }

}


