import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';


@Injectable({
	providedIn: 'root'
})

export class ApiService
{
	
	authToken: any = this.getAccessToken()
	baseURL: string = environment.API_SERVICE_URL

	list: any 	// to get filtered data of either schools, districts, students or teachers
	userObject: any;
	// userData: any;


	constructor(
		private http: HttpClient
	) { }

	// --------------------------------------------------------------
	// 							Authentication
	// --------------------------------------------------------------

	getAccessToken()
	{
		if (localStorage.getItem('token'))
		{
			this.authToken = localStorage.getItem('token').split('=')[0]
			return this.authToken
		}
		else
		{
			return false
		}
		// return localStorage.getItem('token')
	}

	setAccessToken(token: string)
	{
		localStorage.setItem('token', token)
		console.log("User Token saved. ")
	}

	async loginUser(data: any)
	{
		console.log("Request Received...")
		return await this.http.post(this.baseURL + 'user/login', data)
			.toPromise()
			.then(res => res)
	}

	logOut()
	{
		console.log('b')
		this.authToken = false
		localStorage.clear()
	}

	isLoggedIn()
	{
		console.log(this.getAccessToken())
		return this.getAccessToken()
	}

	isLoggedOut()
	{
		return this.getAccessToken()
	}


	/**
	 * Forgot Password functionality
	 * @param data data to be sent to the service
	 * @returns object
	 */
	async forgotPassword(data: any)
	{
		return await this.http.post(this.baseURL + 'user/forget-password', data)
			.toPromise()
			.then(res => res)
	}


	/**
	 *
	 * @param data data
	 * @returns object
	 */
	async resetPassword(data: any)
	{
		return await this.http.post(this.baseURL + 'user/reset-password', data)
			.toPromise()
			.then(res => res)
	}










	// ----------------------------------------------------------------
	// 									Search
	// ----------------------------------------------------------------



	async search(data?: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return await this.http.post(this.baseURL + "cases/cases-list", data, headers)
			.toPromise()
			.then(res => res)
	}




	// ---------------------------------------------------------------------
	// 								Upload Service
	// ---------------------------------------------------------------------

	async sendExcelData(json_data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		console.error("\n\n URL not set yet. ")
		// return await this.http.post(`${this.baseURL}`, json_data, headers)
		// 	.toPromise()
	}




	// ------------------------------------------------------------
	// 							Misc
	// ------------------------------------------------------------
	sendList()
	{
		return this.list
	}










	// -----------------------------------------------------------------------
	// 									Districts
	// -----------------------------------------------------------------------

	/**
	 * retrieve all districts from database which matches search text. Empty text will return all
	 * @param searchData search text to find specific data
	 * @returns list of all districts [no unique data]
	 */
	getAllDistricts(user_id: any)
	{
		let headers: any = {
			params: user_id,
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.get(`${this.baseURL}admin/district`, headers)
			.toPromise()
	}

	/**
	 * retrieve all districts from database which matches search text. Empty text will return all
	 * @param data search text to find specific data
	 * @param user_id  admin Id, only district which admin has access will be returned
	 * @returns list of all districts [no unique data]
	 */
	getDistrict(user_id: any, data?: any)
	{
		let headers: any = {
			params: data,
			// headers: {
			// 	  Authorization: this.authToken
			//   }
		}
		return this.http.get(`${this.baseURL}admin/district/${user_id}`, headers)
			.toPromise()
			.then(res => res)
	}

	getAllStates()
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.get(this.baseURL + "super-admin/state", headers)
			.toPromise()
			.then(res => res)
	}

	// /**
	//  * @returns list of all districts [ unique data ]
	//  */
	// async getUniqueDistricts()
	// {
	// 	let headers: any = {
	// 		headers: {
	// 			Authorization: this.authToken
	// 		}
	// 	}

	// 	return await this.http.get(this.baseURL + "super-admin/getUniqueDistrict", headers)
	// 		.toPromise()
	// }

	/**
	 * register a new district into database
	 * @param data data of the new district to be added
	 * @returns success message or error, on fail otherwise
	 */
	async addDistrict(data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return await this.http.post(this.baseURL + "super-admin/add-district", data, headers)
			.toPromise()
			.then(res => res)
	}

	/**
	 * updates the data of the district
	 * @param id id of the data to be updated
	 * @param data data to be updated
	 * @param data1 query params stateId,cityId
	 * @returns success message or error, on fail otherwise
	 */
	async updateDistrict(id: string | number, data: any, data1?: any)
	{
		let headers: any = {
			params: data1,
			headers: {
				Authorization: this.authToken
			}
		}
		return await this.http.put(this.baseURL + "super-admin/update-district/" + id, data, headers)
			.toPromise()
			.then(res => res)
	}

	/**
	 *
	 * @param id id of the data to be deleted
	 * @param data data to be deleted
	 * @returns success message or error, on fail otherwise
	 */
	deleteDistrict(id: string | number)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.delete(this.baseURL + "super-admin/district/" + id, headers)
			.toPromise()
			.then(res => res)
	}



	// -----------------------------------------------------------------------------------
	// 									District Calendar
	// -----------------------------------------------------------------------------------


	async getDistrictCalendars(user_id: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return await this.http.get(`${this.baseURL}admin/districtCalendar-admin/${user_id}`, headers)
			.toPromise()
			.then(res => res);
	}

	async addDistrictCalendar(data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return await this.http.post(`${this.baseURL}admin/district-calendar`, data, headers)
			.toPromise()
			.then(res => res);
	}

	editDistrictCalendar(id: any, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.put(`${this.baseURL}admin/district-calendar/${id}`, data, headers)
			.toPromise()
			.then(res => res);
	}

	deleteDistrictCalendar(id: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.delete(`${this.baseURL}admin/district-calendar/${id}`, headers)
			.toPromise()
			.then(res => res);
	}

	// -----------------------------------------------------------------------------------
	// 									District Calendar holiday component
	// -----------------------------------------------------------------------------------

	getHolidayList(id: any)
	{
		let headers: any = {
			params: {
				districtCalendarId: id
			},
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.get(`${this.baseURL}admin/district-holiday`, headers)
			.toPromise()
			.then(res => res);
	}

	addHoliday(data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.post(`${this.baseURL}admin/district-holiday`, data, headers)
			.toPromise()
			.then(res => res);
	}

	editHoliday(id: any, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.put(`${this.baseURL}admin/district-holiday/${id}`, data, headers)
			.toPromise()
			.then(res => res);
	}

	deleteHoliday(id: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.delete(`${this.baseURL}admin/district-holiday/${id}`, headers)
			.toPromise()
			.then(res => res);
	}




	// ----------------------------------------------------------------------------
	// 											School - classroom
	// ----------------------------------------------------------------------------

	getSchools(user_id: any, data?: any)
	{
		let headers: any = {
			params: data,
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.get(`${this.baseURL}admin/schoolByAdmin/${user_id}`, headers)
			.toPromise()
			.then(res => res)
	}

	addSchool(data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.post(this.baseURL + 'super-admin/school', data, headers)
			.toPromise()
			.then(res => res)
	}

	editSchool(id: string | number, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.put(`${this.baseURL}super-admin/school/${id}`, data, headers)
			.toPromise()
			.then(res => res)
	}

	deleteSchool(id: string | number)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.delete(`${this.baseURL}super-admin/school/${id}`, headers)
			.toPromise()
			.then(res => res)
	}


	// ----------------------------------------------------------------------------
	// 											Classroom
	// ----------------------------------------------------------------------------

	getClassroom(_id: string)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.get(`${this.baseURL}admin/classroom-by-school/${_id}`, headers)
			.toPromise()
	}


	addClassroom(data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.post(`${this.baseURL}admin/classroom`, data, headers)
			.toPromise()
			.then(res => res)
	}

	editClassroom(classroom_id: string, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.put(`${this.baseURL}admin/classroom/${classroom_id}`, data, headers)
			.toPromise()
			.then(res => res)
	}


	uploadClassroom(data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.post(`${this.baseURL}admin/classroom/upload-excel`, data, headers)
			.toPromise()
			.then(res => res)
	}

	deleteClassroom(id: string | number)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.delete(`${this.baseURL}admin/classroom/${id}`, headers)
			.toPromise()
			.then(res => res)
	}











	// ----------------------------------------------------------------
	// 								Attendance
	// ----------------------------------------------------------------
	addAttendance(adminId, data: any)
	{
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.post(`${this.baseURL}admin/add-attendance/${adminId}`, data, headers)
			.toPromise()
	}

	getStudentsList(data: any)
	{
		let headers: any = {
			params: data,
			headers: {
				Authorization: this.authToken
			}
		};
		return this.http.get(this.baseURL + 'admin/student-list-attendance/', headers)
			.toPromise()
	}

	// ----------------------------------------------------------------
	// 								Admin
	// ----------------------------------------------------------------

	getAdmins(user_id: any, data?: any)
	{
		let headers: any = {
			params: data,
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.get(`${this.baseURL}admin/list/${user_id}`, headers)
			.toPromise()
			.then(res => res)
	}

	addAdmin(data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.post(`${this.baseURL}super-admin/add-admin`, data, headers)
			.toPromise()
			.then(res => res)
	}

	editAdmin(id: string | number, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.put(`${this.baseURL}super-admin/admin/${id}`, data, headers)
			.toPromise()
			.then(res => res)
	}

	deleteAdmin(id: string)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.delete(`${this.baseURL}super-admin/admin/${id}`, headers)
			.toPromise()
			.then(res => res)
	}


	// ------------------------------------------------------------------------------
	// 										Teacher
	// ------------------------------------------------------------------------------


	getTeachers(user_id: any, data?: any)
	{
		let headers: any = {
			params: data,
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.get(`${this.baseURL}admin/teacher/${user_id}`, headers)
			.toPromise()
			.then(res => res)
	}


	addTeacher(data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.post(`${this.baseURL}super-admin/teacher`, data, headers)
			.toPromise()
			.then(res => res)
	}


	editTeacher(id: string | number, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.put(`${this.baseURL}super-admin/teacher/${id}`, data, headers)
			.toPromise()
			.then(res => res)
	}

	deleteTeacher(id: string | number)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.delete(`${this.baseURL}super-admin/teacher/${id}`, headers)
			.toPromise()
			.then(res => res)
	}






	// --------------------------------------------------------------------------
	// 										Student
	// --------------------------------------------------------------------------

	getStudents(user_id: any, data?: any)
	{
		let headers: any = {
			params: data,
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.get(`${this.baseURL}admin/student/${user_id}`, headers)
			.toPromise()
			.then(res => res);
	}


	addStudent(data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.post(`${this.baseURL}super-admin/student`, data, headers)
			.toPromise()
			.then(res => res)
	}

	editStudent(id: string | number, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.put(`${this.baseURL}super-admin/student/${id}`, data, headers)
			.toPromise()
			.then(res => res)
	}

	deleteStudent(id: string | number)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.delete(`${this.baseURL}super-admin/student/${id}`, headers)
			.toPromise()
			.then(res => res)
	}

	atendanceMetric(id: any)
	{
		console.log(id)
		let headers: any = { headers: { Authorization: this.authToken } };
		return this.http.get(this.baseURL + 'user/attendance-metrics/' + id, headers)
			.toPromise()
	}












	// -----------------------------------------------------------------------------------
	// 									District/School Calendar
	// -----------------------------------------------------------------------------------

	// async getDistrictCalendars()
	// {
	// 	let headers: any = {
	// 		headers: {
	// 			Authorization: this.authToken
	// 		}
	// 	}
	// 	return await this.http.get(`${this.baseURL}admin/district-calendar`, headers)
	// 	.toPromise()
	// 	.then(res => res);
	// }

	// async addDistrictCalendar(data: any)
	// {
	// 	let headers: any = {
	// 		headers: {
	// 			Authorization: this.authToken
	// 		}
	// 	}

	// 	return await this.http.post(`${this.baseURL}admin/district-calendar`, data, headers)
	// 	.toPromise()
	// 	.then(res => res);
	// }

	// async editDistrictCalendar(id: any, data: any)
	// {
	// 	let headers: any = {
	// 		headers: {
	// 			Authorization: this.authToken
	// 		}
	// 	}
	// 	return await this.http.put(`${this.baseURL}admin/district-calendar/${id}`, data, headers)
	// 	.toPromise()
	// 	.then(res => res);
	// }

	// async deleteDistrictCalendar(id: any, data: any)
	// {
	// 	let headers: any = {
	// 		headers: {
	// 			Authorization: this.authToken
	// 		}
	// 	}
	// 	return await this.http.put(`${this.baseURL}admin/update-district-calendar/${id}`, data, headers)
	// 	.toPromise()
	// 	.then(res => res);
	// }

	// ---------------------------------------------------------------
	// 								School Calendar
	// ---------------------------------------------------------------

	async getSchoolCalendars(adminId: any, school_id: any)
	{
		let headers
		if (school_id == "none")
		{
			headers = {
				headers: {
					Authorization: this.authToken
				}
			}
		}
		else
		{
			headers = {
				params: {
					schoolId: school_id
				},
				headers: {
					Authorization: this.authToken
				}
			}
		}


		return await this.http.get(`${this.baseURL}admin/schoolCalendar-admin/${adminId}`, headers)
			.toPromise()
	}

	addSchoolCalendar(data: any)
	{
		let headers = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.post(`${this.baseURL}admin/school-calendar`, data, headers)
			.toPromise()
	}

	editSchoolCalendar(_id: string, data: any)
	{
		let headers = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.put(`${this.baseURL}admin/school-calendar/${_id}`, data, headers)
			.toPromise()
	}

	deleteSchoolCalendar(id: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.delete(`${this.baseURL}admin/school-calendar/${id}`, headers)
			.toPromise()
			.then(res => res);
	}

	//---------------------------------------------------------------
	// 								school holiday calendar
	// ---------------------------------------------------------------

	getSchoolHolidayList(id: any)
	{
		let headers = {
			params: {
				schoolCalendarId: id
			},
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.get(`${this.baseURL}admin/school-holiday`, headers)
			.toPromise()
	}

	addSchoolHoliday(data: any)
	{
		let headers = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.post(`${this.baseURL}admin/school-holiday`, data, headers)
			.toPromise()
	}

	editSchoolHoliday(id: string, data: any)
	{
		let headers = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.put(`${this.baseURL}admin/school-holiday/${id}`, data, headers)
			.toPromise()
	}


	deleteSchoolHoliday(id: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.delete(`${this.baseURL}admin/school-holiday/${id}`, headers)
			.toPromise()
			.then(res => res);
	}
	
	// --------------------------------------------------------------
	//		 					RfidLookup
	// --------------------------------------------------------------

	rfidSearch(id:any,data: any) {
		console.log("hi in rfid")
		let headers: any = {	params: data, headers: { Authorization: this.authToken } };
		return this.http.get(`${this.baseURL}admin/rfid-lookup/${id}`, headers)
			.toPromise()
			.then(res => res)
	}

	// ---------------------------------------------------------------
	// 								Reporting
	// ---------------------------------------------------------------


	getDistrictReport(id: any, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.post(`${this.baseURL}admin/district-report/${id}`, data, headers)
			.toPromise()
			.then(res => res);
	}

	getAttendanceDetail(id: any, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.post(`${this.baseURL}admin/attendance-detail-report/${id}`, data, headers)
			.toPromise()
			.then(res => res);
	}

	getStudentReport(id: any, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.post(`${this.baseURL}admin/student-report/${id}`, data, headers)
			.toPromise()
			.then(res => res);
	}

	getSchoolReport(id: any, data: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.post(`${this.baseURL}admin/school-reporting/${id}`, data, headers)
			.toPromise()
	}





	// ---------------------------------------------------------------
	// 								Profile
	// ---------------------------------------------------------------

	async getProfileInfo(id: any)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return await this.http.get(`${this.baseURL}user/getProfile/${id}`, headers)
			.toPromise()
	}

	async updateProfile(id: any, data: any)
	{
		console.log('AuthToken: ', this.authToken)
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return await this.http.put(`${this.baseURL}user/updateProfile/${id}`, data, headers)
			.toPromise()
	}

	setName(name: string)
	{
		localStorage.setItem('user_name', name)
		console.log('User Name has been changed. ')
	}

	getName()
	{
		return localStorage.getItem('user_name')
	}










	// ----------------------------------------------------------------
	// 									Misc
	// ----------------------------------------------------------------




	set userData(user_data: any)
	{
		this.userObject = user_data
		console.log("user_datatat", this.userData)
	}

	get userData()
	{
		if (this.userObject)
		{
			return this.userObject;
		}
		else
		{
			return JSON.parse(localStorage.getItem('userData'))
		}
	}

	getTeacherByMultipleSchool(schoolId: any)
	{
		let school = schoolId.join(",")
		let headers: any = {
			params: {
				schoolId: school
			},
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.get(`${this.baseURL}teacher/teachers-by-school`, headers)
			.toPromise()
			.then(res => res)
	}

	getGradeByMultipleteacher(teacherId:any){
		let teacher = teacherId.join(",")
		let headers: any = {
			params: {
				teacherId: teacher
			},
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.get(`${this.baseURL}teacher/grades-by-teacher`, headers)
			.toPromise()
			.then(res => res)

	}

	async getCityByState(districtName: string)
	{
		let headers: any = {
			params: {
				districtName: districtName
			},
			headers: {
				Authorization: this.authToken
			}
		}

		return await this.http.get(`${this.baseURL}super-admin/getCityByDistrict`, headers)
			.toPromise()
			.then(res => res)
	}

	async getSchoolByCity(cityName: string)
	{
		let headers: any = {
			params: {
				physicalCity: cityName
			},
			headers: {
				Authorization: this.authToken
			}
		}

		return await this.http.get(`${this.baseURL}super-admin/schoolByCity`, headers)
			.toPromise()
			.then(res => res)
	}


	getTeacherBySchool(schoolId: string)
	{
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}

		return this.http.get(`${this.baseURL}teacher/getTeacherBySchool/${schoolId}`, headers)
			.toPromise()
			.then(res => res)
	}

	getCalendarMulSchool(schoolId:any){
		let schoolIds={schoolId:schoolId}
		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.post(`${this.baseURL}admin/getCalendar-by-mul-school`,schoolIds, headers)
			.toPromise()
			.then(res => res)
	}

	async getSchoolByDistrict(adminId:any,data: any, search?: any)
	{
		console.log(data);
		
		let data1 = data.join(",")
		let headers: any = {
			params: {
				districtId: data1,
				search: search

			},
			headers: {
				Authorization: this.authToken
			}
		}
		console.log(data1);


		return await this.http.get(`${this.baseURL}admin/get-school-by-district/${adminId}`, headers)
			.toPromise()
			.then(res => res)
	}

	getClassroomByTeacher(teacher_id)
	{

		let headers: any = {
			headers: {
				Authorization: this.authToken
			}
		}
		return this.http.get(`${this.baseURL}admin/classroom-by-teacher/${teacher_id}`, headers)
			.toPromise()
			.then(res => res)
	}

	uploadTeacher(file, adminId: any): Observable<any>
	{
		const formData = new FormData();
		console.log("formdatat before", formData);

		// Store form name as "file" with file data
		formData.append("file", file, file.name);
		return this.http.post(`${this.baseURL}admin/upload-teacherList/${adminId}`, formData)
	}

	uploadStudent(file, adminId: any): Observable<any>
	{
		const formData = new FormData();
		console.log("formdatat before", formData);

		// Store form name as "file" with file data
		formData.append("file", file, file.name);
		return this.http.post(`${this.baseURL}admin/upload-studentList/${adminId}`, formData)
	}

}
